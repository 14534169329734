
.title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 1rem;
  height: 1em;
}

.minimize-icon {
  height: 1em;
}

.expansion-icon-div {
  height: 2.2em;
  padding-right: 10px;
  display: flex;
  align-items: center;
}