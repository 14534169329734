@import url('https://fonts.googleapis.com/css2?family=Beth+Ellen&family=Chicle&display=swap');

.landing-page {
  position: relative;
  background-color: #d4c3a6;
  /* Darker tan color palette */
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  /* Hide overflow to prevent scroll bars */
  
}

.landing-page img {
  border: 1.4px solid black;
  border-radius: 5px;
  width: 28px;
  height: 28px;
  margin-right: 8px;
  
}

.landing-page__home-link {
  position: absolute;
  top: 10px; /* Adjust as needed */
  left: 25px; /* Adjust as needed */
  z-index: 2; /* Ensure it's clickable and above other elements */
  display: flex;
  flex-direction: row;
  align-items: center;

  font-family: "Beth Ellen", cursive;
  font-weight: 400;
  font-style: normal;
  text-decoration: none; /* Removes underline from the link */

}

.landing-page__sun-pattern {
  position: absolute;
  top: 0;
  left: 0;
  width: 200%;
  height: 200%;
  background-image: radial-gradient(circle, #f9f5e6 30%, transparent 50%);
  background-size: 150px 150px;
  /* Adjust size of pattern */
  z-index: -1;
  /* Ensure pattern appears behind content */
}

.landing-page__content {
  text-align: center;
  z-index: 1;
  /* Ensure content appears above sun pattern */
}

.landing-page_body{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.landing-page__title {
  font-size: 3rem;
  color: #fff;
  font-family: 'Segoe Script', sans-serif;
  /* Use a font resembling an ancient scroll text */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  /* White newspaper text effect */
  margin-bottom: 20px;
  
}

.landing-page__description {
  font-size: 1.5rem;
  color: #fff;
  max-width: 600px;
  margin:  auto;
  margin:8px;
}

.landing-page__button {
  background-color: #fff;
  color: #000;
  font-size: 1.2rem;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 20px;
}

.landing-page__button:hover {
  background-color: #ddd;
  /* Lighter shade on hover */
}