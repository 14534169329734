.footer {
  background-color: #9cd9ed; /* Light blue background */
  color: #ffffff;
  text-align: center;
  font-size: small;
  font-style: italic;
  padding: 20px 0;
  width: 100%;
  bottom: 0;
  left: 0;
}

iframe {
  width: 100%; /* Make sure iframe width adjusts to container */
  height: 50em; /* Make sure iframe height adjusts to container */
  margin: 0; /* Reset margin to zero */
}

.iframeContainer{
  width: 100%;
  height: 50em;
  margin: 0px;
  padding: 0px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.toggle-button {
  background-color: #67a2ee;
  border: 2px solid transparent;
  border-radius: 2px;
  padding: 5px 10px; /* Adjust as needed */
  width: 20%;
  cursor: pointer;
  transition: background-color 0.1s, border-color 0.1s;
  flex: 0 0 auto;
  color: white;
  font-weight: 350;
  font-size: 14px; /* Adjust as needed */
}