.reading-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100vw; /* Ensure the container fits the viewport width */
  box-sizing: border-box; /* Include padding and borders in the element's total width and height */
}

.spread-selection {
  display: flex;
  padding-top: 20px;
  padding-left:20px;
  padding-right:8px;
  width: 100%; /* Take full width of its container */
  box-sizing: border-box; /* Include padding in width calculation */
}

.spread-buttons {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  padding: 10px;
  width: calc(100% - 20px); /* Subtract horizontal paddings from total width */
  box-sizing: border-box; /* Manage padding and border within the specified width */
  margin-bottom: 20px;
  
}

.spread-button {
  background: #74a3df;
  border: 2px solid transparent;
  border-radius: 2px;
  padding: 10px 20px;
  margin-right: 10px;
  cursor: pointer;
  transition: background-color 0.1s, border-color 0.1s;
  flex: 0 0 auto; /* Prevents the buttons from shrinking */
  color: white;
  font-weight: 350;
}

.spread-button.selected {
  background: #061375;
  border-color: #2553de;
}

.spread-button:hover {
  background: #6187cd;
}

.cards-display{
  display: flex;
  flex-direction: row;
  flex-wrap:wrap;
  justify-content:center
}

.cards-display > div {
  margin: 16px;
  padding: 16px;
}